@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  @apply font-mono bg-green-200 text-green-900 px-2 pt-0 mb-4 rounded-md  border;
  padding-bottom: 1px;
  /* font-size: 1.1em; */
  border-color: rgb(145, 198, 166);
  /* line-height: 1.9; */
}

h1, h2, h3, .font-header, .card-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
}
